import React from 'react';
import {Header, Search} from 'semantic-ui-react';
import SEO from '../components/shared/SEO/SEO';
import Page from '../components/site/layouts/Page';

const NotFoundPage = () => (
  <Page sidebar={false}>
    <SEO title="404: Not found" />
    <div className="text-centered large-bm">
      <Header as="h1" className="text-centered">
        Not Found
      </Header>
      <Header.Subheader className="text-centered text-upper">
        Oops! That page can&apos;t be found.
      </Header.Subheader>
      <p>
        Let&apos;s get you to the right place.
        <br />
        Try one of the links above or search through this site.
      </p>
      <Search />
    </div>
  </Page>
);

export default NotFoundPage;
